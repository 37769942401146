.cart-container {
  padding-top: 100px;
  width: 30vw;
  height: calc(100vh - 75px);
  position: relative;
}
.small-cart {
  /* padding-top: 100px; */
  position: fixed;
  height: calc(100vh - 75px);
  top: 75px;

  border: 1px solid black;

  /* bottom: calc(env(safe-area-inset-bottom) + 100px); */
  display: flex;
  flex-direction: column;
  color: rgba(0, 0, 0, 0.8);
  background-color: #f8f8f8;
  z-index: 10;
  width: 100vw;
  padding: 0 10px;
  overflow-y: auto;
}
.cart-container h2 {
  width: 100%;
  text-align: left;
  padding-bottom: 20px;
  color: black;
}
.cart-container::before {
  content: "";
  position: absolute;
  height: calc(100vh - 75px - 20vh);
  background-color: rgba(0, 0, 0, 0.201);
  width: 2px;
  padding-top: -100px;
  top: 50%;
  left: -4vw;
  transform: translate(-50%, -50%);
}
.order-container {
  display: flex;
  flex-direction: column;
  align-items: space-between;
  height: 50%;
  width: 90%;
  padding: 0 10px;
  overflow-y: auto;
  overflow-x: hidden;
}

.empty-cart {
  display: flex;
  height: 100%;
  width: 90%;
  margin-bottom: 20px;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  opacity: 0.6;
}
.order-container::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.order-container::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}
/* Handle */
.order-container::-webkit-scrollbar-thumb {
  background: var(--green);
  border-radius: 10px;
}
/* Handle on hover */
.order-container::-webkit-scrollbar-thumb:hover {
  background: #019f4a;
}
.selected-container {
  height: 90px;
  display: flex;
  margin: 10px 0;
  position: relative;
}
.selected-container h2 {
  color: black;
}
.selected-image {
  height: 90px;
  width: 120px;
}
.selected-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0 10px;
}
.selected-title {
  font-size: 15px;
}
.selected-numbers {
  display: flex;
  align-items: center;
  font-size: 14px;
}
.selected-room-numbers {
  font-size: 14px;
}
.counter-buttons {
  width: 20px;
  height: 20px;
  border-radius: 3px;
  font-size: 15px;
  border: none;
  background: #eff2f7;
  border: 1px solid rgba(128, 128, 128, 0.331);
  box-shadow: inset 0 0 10px #eff2f7;
}
.counter-buttons:disabled {
  opacity: 0.7;
}
.selected-per-container {
  font-size: 14px;
  padding: 0 5px;
}
.selected-price {
  font-size: 14px;
}
.selected-total {
  font-size: 14px;
}

.discount-container {
  margin: 20px 0;
  height: 100px;
  width: 90%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.discount-container:before,
.discount-container:after {
  content: "";
  position: absolute;
  width: 100%;
  right: 50%;
  transform: translate(50%, 50%);
  height: 2px;

  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;

  background-color: rgba(0, 0, 0, 0.201);
}
.discount-container:before {
  top: 0px;
}
.discount-container:after {
  bottom: 0px;
}
.discount-container h2 {
  font-size: 18px;
  padding: 0 0 5px 0;
}
.enter-discount {
  display: flex;
  align-items: center;
}
.enter-discount .discount-input {
  height: 40px;
  width: calc(100% - 70px);
  background: #eff2f7;
  border: 1px solid grey;
  box-sizing: border-box;
}

.discount-button {
  height: 40px;
  width: 70px;
  border: 1px solid var(--green);
  background: var(--green);
  color: rgb(245, 245, 245);
  font-size: 18px;
  font-family: "Roboto" Cambria, Cochin, Georgia, Times, "Times New Roman",
    serif;
  margin: 0 5px;
}

.remove-from-cart {
  position: absolute;
  top: 50%;
  right: 20px;
  font-size: 24px;
  opacity: 0.2;
  transform: translate(0, -50%);
  cursor: pointer;
}
.remove-from-cart:hover {
  opacity: 0.5;
}

.price-container {
  width: 90%;
}
.price-container .price {
  display: flex;
  justify-content: space-between;
  padding-bottom: 5px;
  opacity: 0.8;
}

@media only screen and (max-width: 1500px) {
  .cart-container {
    width: 40vw;
  }
  .selected-image {
    height: 75px;
    width: 100px;
  }
  .cart-container::before {
    left: -2.5vw;
  }
}
