/* header */
.header {
  position: fixed;
  width: 100%;
  height: 75px;
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;
  grid-template-rows: 1fr;
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  font-size: var(--fs);
  align-items: center;
  /* justify-items: center; */
  z-index: 100;
  color: white;
  text-shadow: 0 0 2px white;
}
/* logo */
.logo {
  display: flex;
  align-items: center;
  margin: 0 0 0px 20%;
  z-index: 100;
  font-size: calc(var(--fs) + 3px);
  text-decoration: none;
  color: black;
  /* color: #010101; */
}
.smaller-logo {
  display: none;
}

.green {
  color: var(--green);
}
.yellow {
  color: var(--yellow);
}

.large {
  font-size: 40px;
  text-shadow: 0 0 3px rgba(0, 0, 0, 0.784);
}
.star-icon {
  font-size: 35px !important;
  color: var(--red) !important;
  transform: rotate(0deg);
  margin: 10px 0 10px 1px;
  animation: moveStar 1s linear;
  animation-delay: 1.5s;
  background-color: none;
}
@keyframes moveStar {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(5deg);
  }
}
/* navigation */
.navigation {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 1s ease-in;
}
.book {
  display: flex;
  justify-content: flex-end;
  margin: 0 20% 0 0;
  align-items: center;
}
.nav-item {
  color: white;
  text-shadow: 0 0 2px white;
  text-decoration: none;
  margin: 0 10px;
  padding: 8px 15px;
  cursor: pointer;
  position: relative;
  z-index: 20;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
}

.background-nav {
  position: relative;
}
.background-nav:before {
  position: absolute;
  content: "";
  background: rgb(230, 230, 230);
  height: 45px;
  width: calc(100% + 20px);
  z-index: -1;
  top: -5px;
  left: -10px;
  border: 1px solid black;
  border-bottom: none;
}
.important-link {
  font-size: calc(var(--fs) + 4px);
  display: flex;
  padding: 5px 5px;
  align-items: center;
  text-decoration: none;
  color: white;
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  transition: transform 0.3 ease-in;
}
.important-link::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background-color: var(--bg);
  transition: opacity 300ms, transform 300ms;
  /* animation: underlineButton 2s linear infinite; */
}
.important-link:hover {
  transform: scale(1.1);
}

.language-container {
  position: relative;
  display: flex;
  justify-content: center;
}
.language-dropdown {
  display: flex;
  align-items: center;
}
.language-icon {
  font-size: calc(var(--fs) + 2px) !important;
  margin-top: -1px;
}
.flag-images {
  height: 22px;
  width: 26px;
  padding: 0 2px;
  margin-right: 2px;
}
.dropdown-content {
  position: absolute;
  flex-direction: column;
  align-items: flex-start;
  transform: translate(0%, 50%);
  top: -21px;
  width: 100%;
  background: rgb(248, 248, 248);
  border: 1px solid black;
  /* border-top: none; */
}
.dropdown-item {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  width: 100%;
  height: 40px;
  cursor: pointer;
  color: black;
  /* margin-bottom: 10px; */
  padding: 10px 0px;
  padding-left: 25px;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
}

.dropdown-item:hover {
  background: rgb(230, 230, 230);
}

.contact {
  background: none;
  border-radius: 8px;
  transition: background 0.3s ease-in-out;
}

.dropdown-nav {
  display: none;
}

.menu-icon {
  font-size: 40px !important;
  text-shadow: 10px 10px 10px black !important;
  z-index: 100;
  cursor: pointer;
}
.nav {
  position: absolute;
  left: -100%;
  transition: all 0.2s linear;
  font-size: calc(var(--fs));
}
.nav-wrapper {
  border-bottom: 1px solid black;
  width: 100%;
  transition: all 0.3s ease-in-out;
}
.accordion {
  width: 100%;
  border: none !important;
  box-shadow: none !important;
  padding: 0px 0 !important;
  margin: 0 0 !important;
  background-color: transparent !important;
}

.nav-list-container {
  background-color: transparent !important;
  box-shadow: none !important;
  /* width: 100%; */
  height: 48px;
  padding: 0px 10px !important;
  border: none !important;
  border-bottom: none !important;
  margin: 10px 0 !important;
  display: flex;
  align-items: center;
}
.nav-language-icon {
  margin: 2px 0 0 2px;
  font-size: calc(var(--fs) + 1px) !important;
}
.nav-wrapper:hover {
  background: rgb(230, 230, 230) !important;
}

.accordion-dropdown {
  background: #f8f8f8 !important;
  box-shadow: none !important;
  display: flex;
  flex-direction: column;
  padding: 0 0 !important;
  margin: 0 0 !important;
}
.accordion-item:hover {
  background: rgb(230, 230, 230) !important;
}
.list-item {
  text-decoration: none;
  color: black;
}
.accordion-item {
  text-decoration: none;
  color: black;
  padding: 15px 30px;
  display: flex;
  align-items: center;
  border-top: 1px solid black;
}

.nav-book {
  position: absolute;
  bottom: 114px;
  width: 100%;
  padding: 15px 0;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  display: flex;
  justify-content: center;
}
.nav-book-item {
  color: black;
  text-decoration: none;
  position: relative;
  overflow: hidden;
  width: fit-content;
  border-radius: 15px;
  padding: 5px 10px;
  transition: all 0.3s ease-in-out;
}

.nav-book-item::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background-color: var(--red);
  transition: opacity 300ms, transform 300ms;
  /* animation: underlineButton 2s linear infinite; */
}
.nav-book:hover {
  background: rgb(230, 230, 230);
}
.nav-book:hover .nav-book-item {
  transform: scale(1.1);
}
.feature-icon {
  font-size: calc(var(--fs) + 3px) !important;
  margin-right: 2px;
}
@media only screen and (max-height: 809px) {
  .accordion-item {
    padding: 10px 30px;
  }
  .nav-list-container {
    margin: 5px 0 !important;
  }
  .nav-book {
    padding: 10px 0;
  }
}

@media only screen and (max-height: 702px) {
  .accordion-item {
    padding: 5px 30px;
  }
  .nav-list-container {
    margin: 0px 0 !important;
  }
  .nav-book {
    padding: 5px 0;
  }
  .nav {
    font-size: calc(var(--fs) - 2px) !important;
  }
}
@media only screen and (max-height: 620px) {
  .accordion-item {
    padding: 5px 10px;
  }
}
@media only screen and (max-width: 1340px) {
  .header {
  }
}
@media only screen and (max-width: 1140px) {
  .header {
    font-size: calc(var(--fs) - 1px);
  }
}

@media only screen and (max-width: 900px) {
  .header {
    grid-template-columns: 1fr 3fr 1fr;
  }
  .navigation {
    display: none;
  }
  .large {
    padding: 0;
  }

  .dropdown-nav {
    display: flex;
    align-items: center;
    grid-column: 1;
    grid-row: 1;
    margin: 0 0 0px 20%;
  }

  .nav {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 30%;
    height: calc(100vh - 75px);
    background-color: #f8f8f8;
    position: absolute;
    top: 75px;
    left: 0;
    border-right: 1px solid black;
    font-size: calc(var(--fs) - 2px);
  }
  .feature-icon {
    font-size: calc(var(--fs) + 1px) !important;
  }
  .nav-language-icon {
    font-size: calc(var(--fs) - 1px) !important;
    margin: 1px 0 0 2px;
  }
  .nav-item2 {
    color: black !important;
    align-items: flex-start;
  }

  .book {
    grid-column: 3;
  }
  .logo {
    grid-row: 1;
    grid-column: 2;

    margin: 0;
    justify-content: center;
    width: 100%;
  }
}

@media only screen and (max-width: 600px) {
  .header {
    grid-template-columns: 1fr 1fr 1fr;
    font-size: calc(var(--fs) - 5px);
  }
  .nav {
    width: 50%;
    font-size: calc(var(--fs) - 3px);
  }
  .feature-icon {
    font-size: calc(var(--fs)) !important;
  }
  .logo {
    display: none;
  }
  .flag-images {
    height: 18px;
    width: 22px;
  }
  .smaller-logo {
    display: flex;
    grid-row: 1;
    grid-column: 2;
    margin: 0;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  .large {
    font-size: 40px;
    text-shadow: 0 0 1px black;
  }
  .star-icon {
    font-size: 40px !important;
    margin: 9px 0px 10px 0px;
  }
  .important-link {
    font-size: calc(var(--fs) + 2px);
    padding: 4px 6px;
  }

  .menu-icon {
    font-size: 35px !important;
  }
  .nav-item {
    font-size: calc(var(--fs) - 2px);
    padding: 3px 8px;
  }
  .contact {
    padding: 5px 8px;
  }
}

@media only screen and (max-width: 400px) {
  .header {
    font-size: calc(var(--fs) - 7px);
  }
  .large {
    font-size: 35px;
  }
  .star-icon {
    font-size: 35px !important;
    /* margin-top: 10px; */
  }
  .important-link {
    font-size: calc(var(--fs));
    padding: 4px 6px;
  }

  .menu-icon {
    font-size: 30px !important;
  }

  .nav-list-container {
    font-size: calc(var(--fs) - 2px) !important;
  }
}

/* SMALL HEADER   */
.small-header {
  position: absolute;
  inset: 0 0;
  display: flex;
  width: 100%;
  height: 75px;
  background: rgb(248, 248, 248);
  border-bottom: 1px solid black;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
}

.small-header .logo {
  position: relative;
}
.nav-back {
  display: flex;
  align-items: center;
  font-size: 16px;
  position: absolute;
  text-decoration: none;
  left: 2%;
  top: 30px;
  transition: text-shadow 0.3s linear;
}
.nav-back:hover {
  text-shadow: 0 0 8px rgba(51, 51, 51, 0.237);
}

.small-logo {
  display: flex;
  align-items: center;
  margin: 0 0 0px 20%;
  z-index: 100;
  font-size: calc(var(--fs) + 3px);
  text-decoration: none;
}
