.FormGroup {
  /* margin: 0 15px 20px; */
  padding: 0;
  border-style: none;
  background-color: #eff2f7;
  will-change: opacity, transform;
  /* box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08),
    inset 0 1px 0 #829fff; */
  border: 1px solid grey;
  border-radius: 4px;
}

.FormRow {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  margin-left: 15px;
  background: #eff2f7;
  height: 38px;
  /* width: 100%; */
  /* border-top: 1px solid #819efc; */
}

.StripeElement--webkit-autofill {
  background: transparent !important;
}

.StripeElement {
  width: 100%;
  padding: 11px 15px 11px 0;
}

.payment-button {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  width: calc(100% - 30px);
  height: 40px;
  margin: 40px 15px 0;
  background-color: var(--green);
  box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08),
    inset 0 1px 0 var(--green);
  border: 1px solid grey;
  border-radius: 4px;
  color: #fff;
  font-weight: 600;
  cursor: pointer;
  transition: all 100ms ease-in-out;
  will-change: transform, background-color, box-shadow;
}

.payment-button:active {
  background-color: #d782d9;
  box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08),
    inset 0 1px 0 #e298d8;
  transform: scale(0.99);
}
.error-handle {
  position: absolute;
  color: var(--red);
}

.success-container {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 100px;
  background-color: white;
}

.success-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.click {
  background-color: "#00853f";
  color: "#fff";
  border-radius: 10px;
  padding: "10px 15px";
  text-decoration: "none";
}
