.chef-page {
  height: calc(100vh - 75px);
  width: 100%;
  background: #000;
  align-items: center;
  justify-content: center;
  background-size: cover;
  object-fit: fill;
}

.chef-page .lighten {
  position: relative;
  height: calc(100vh - 75px);
  width: 100%;
  background-color: rgba(104, 118, 104, 0.856);
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.chef-container {
  height: 90%;
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  border-radius: 10px;
  /* background-color: rgba(255, 255, 255, 0.247); */
  box-shadow: 0 0 14px 5px rgba(0, 0, 0, 0.249);
  background-image: radial-gradient(
    rgba(168, 168, 184, 0.447),
    rgba(0, 0, 0, 0)
  );
  color: white;
  text-shadow: 0 0 4px rgb(3, 3, 3);
}
.chef-title {
  margin-top: 20px;
  padding: 10px;
  width: 100%;
  text-align: center;
  font-size: 2.7vw;
  font-family: "Satisfy", "Roboto", Cambria, Cochin, Georgia, Times,
    "Times New Roman", serif;
  position: relative;
}
.chef-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
}
.chef-details .chef-book-now {
  font-family: "Roboto", Cambria, Cochin, Georgia, Times, "Times New Roman",
    serif;
  font-size: 1.5vw;
  border: none;
  background: none;
  text-decoration: none;
  color: white;
  text-shadow: 0 0 4px rgb(3, 3, 3);
  box-shadow: 2px 2px 10px 1px rgb(0 0 0 / 9%);
  position: relative;
  padding: 5px 10px;
  border: 2px solid #f8f8f8;
  border-radius: 5px;
  transition: transform 0.3s linear;
  cursor: pointer;
}
.chef-details .chef-book-now:hover {
  transform: scale(1.05);
}

.chef-details p {
  line-height: 1.9vw;
  color: white;
  font-size: 1.3vw;
  padding-bottom: 40px;
  width: 70%;
}

.chef-content {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
}
.chef-gallery-container {
  position: relative;
  width: 50%;
  height: 100%;
  display: grid;
  grid-template: 1fr / 1fr;
  place-items: center;
}
.chef-image {
  width: 70%;
  height: auto;
  transform: rotate(7.5deg);
  border: 5px solid white;
  box-shadow: 2px 2px 10px 1px rgba(0, 0, 0, 0.249);
}

@media screen and (max-width: 2200px) {
  .chef-image {
    width: 75%;
  }
}
@media screen and (max-width: 1600px) {
  .chef-image {
    width: 85%;
  }
}
@media screen and (max-width: 1100px) {
  .chef-title {
    font-size: calc(var(--fs) + 15px);
    padding-bottom: 10px;
  }
  .chef-image {
    width: 90%;
  }
  .chef-details p {
    font-size: calc(var(--fs));
    line-height: calc(var(--fs) + 6px);
    width: 80%;
    padding-bottom: 20px;
  }
  .chef-details .chef-book-now {
    font-size: calc(var(--fs) - 1px);
    border: 1px solid white;
  }
}
@media screen and (max-width: 900px) {
  .chef-content {
    flex-direction: column;
    width: 100%;
  }
  .chef-title {
    font-size: calc(var(--fs) + 15px);
    padding-bottom: 10px;
  }
  .chef-details {
    width: 90%;
    height: 45%;
  }
  .chef-details p {
    font-size: calc(var(--fs) - 2px);
    line-height: calc(var(--fs) + 3px);
    width: 80%;
    padding-bottom: 20px;
  }
  .chef-details .chef-book-now {
    font-size: calc(var(--fs) - 1px);
    border: 1px solid white;
  }
  .chef-image {
    width: 110%;
  }
}
@media screen and (max-width: 800px) {
  .chef-image {
    width: 120%;
  }
}
@media screen and (max-width: 700px) {
  .chef-image {
    width: 130%;
  }
}
@media screen and (max-width: 600px) {
  .chef-image {
    width: 140%;
  }
}
@media screen and (max-width: 550px) {
  .chef-details {
    width: 90%;
    height: 50%;
  }
  .chef-details p {
    font-size: calc(var(--fs) - 2px);
    line-height: calc(var(--fs) + 2px);
    width: 100%;
  }
  .chef-image {
    width: 150%;
  }
  .chef-details .chef-book-now {
    font-size: calc(var(--fs) - 2px);
    border: 1px solid white;
  }
}
