.booking {
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: Roboto, Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
}

.booking-content {
  display: flex;
  justify-content: center;
  margin-top: 50px;
  width: 100%;
  background: rgb(248, 248, 248);
}

.book-container {
  width: 70vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: calc(100vh - 75px);
  position: relative;
  /* border: 1px solid #000; */
}
.form-options {
  width: 50vw;
}

.book-steps {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 15px;
  width: calc(50vw - 30px);
  height: 100px;
  /* border: 1px solid black; */
}
.steps {
  height: 20px;
  width: 20px;
  border: 2px solid var(--green);
  border-radius: 50%;
  position: relative;
  display: flex;
  /* justify-content: center; */
  /* align-items: center; */
}
.steps span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: grey;
  margin: none;
  padding: 0;
  height: 10px;
  width: 10px;
  border-radius: 50%;
}
.steps p {
  position: absolute;
  width: 200px;
  text-align: center;
  left: 50%;
  transform: translate(-50%, 0%);
  bottom: -30px;
}
.bar {
  background: var(--green);
  height: 2px;
  width: calc((50vw / 2) - 30px);
}
.add-on-small {
  display: none;
}
.controls {
  width: 50vw;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0;
  padding: 0;
  z-index: 10001;
}
.controls-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.controls-button {
  border: 1px dotted white;
  background: none;
  color: white;
  padding: 10px 10px;
  width: fit-content;
  font-size: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px;
}
.open-cart-container {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-direction: column;
  font-size: 15px;
  width: 90%;
}
.open-cart-container > button {
  background-color: var(--green);
}

.open-cart-container > button > span {
  font-size: 15px;
  margin-top: 5px;
  color: #1d2439;
}

.cart-arrow {
  height: 50px !important;
  width: 50px !important;
  margin: 0 !important;
  margin-bottom: 20px !important;
  color: var(--green);
}
.next {
  background: var(--green);
}
.prev {
  color: var(--green);
  border: 1px solid var(--green);
}

.personal-form,
.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50vw;
  margin: 5vh 0 0 0;
  padding-right: 10px;
  /* height: calc(100vh - 275px - 5vh); */
  position: relative;
}
.form-header,
.add-on-header {
  text-align: left;
  width: 100%;
  font-size: 24px;
  padding-bottom: 10px;
}
.form .field {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px 0;
}

.field label {
  padding: 5px 0;
  color: rgb(100, 100, 100);
  font-weight: 900;
  font-size: 18px;
}
.field-input {
  height: 38px;
  border-radius: 5px;
  border: 1px solid rgb(160, 160, 160);
  background: #eff2f7;
  padding-left: 10px;
}
.field-input:focus {
  border-color: green;
}

.field input::placeholder {
  /* padding-left: 10px; */
  opacity: 0.5;
}

.field .react-select-component {
  height: 40px !important;
  padding: 0;
  border: 1px solid rgb(160, 160, 160);
  border-radius: 5px;
  background-color: rgb(239, 242, 247);
}

.react-select-component::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.react-select-component::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
.react-select-component::-webkit-scrollbar-thumb {
  background: var(--green);
  border-radius: 10px;
}

/* Handle on hover */
.react-select-component::-webkit-scrollbar-thumb:hover {
  background: #019f4a;
}

.react-select-component:focus {
  border-color: green !important;
}

.react-select__control:focus {
  border: 1px solid green !important;
}
.true__control,
.css-1s2u09g-control,
.css-b62m3t-container,
.select-component.css-b62m3t-container {
  background-color: #ffffff !important;
  outline: none !important;
}

/* DATE PICKER  */
.rdrCalendarWrapper {
  width: 100%;
  border: 1px solid rgb(160, 160, 160);
  border-radius: 5px;
  background-color: rgb(255, 255, 255);
  box-sizing: border-box;
}
.rdrDateDisplayWrapper {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  /* background-color: var(--green) !important; */
}

.rdrDateInput {
  position: relative;
  margin-top: 15px;
}

.rdrDateInput:before {
  color: black;
  position: absolute;
  top: -12px;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: 900;
  font-family: "Roboto", Cambria, Cochin, Georgia, Times, "Times New Roman",
    serif;
}
.rdrDateInput:first-child::before {
  content: "Check In: ";
}
.rdrDateInput:last-child::before {
  content: "Check Out: ";
}
.rdrMonths {
  width: 100%;
}
.rdrMonth {
  width: 50% !important;
}

.rdrDayDisabled {
  opacity: 0.5;
  background: none !important;
}

/* ADD ON form */

.add-on-form {
  font-size: 15px;
}
.add-on-form .sub-container {
  width: 50vw;
  padding: 20px 0;
  display: flex;
  position: relative;
}
.sub-container:after {
  content: "";
  position: absolute;
  width: 45vw;
  height: 2px;
  bottom: 0px;
  right: 50%;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  transform: translate(50%, 0);
  background-color: rgba(0, 0, 0, 0.201);
}
.sub-container:last-child::after {
  display: none;
}

.add-on-image {
  height: 100px;
  width: 140px;
  object-fit: fill;
  border: 2px solid rgb(255, 255, 255);
  margin: 0 10px 0 0px;
}
.add-on-details {
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: calc(50vw - 240px);
}
.add-on-add {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-left: 10px;
  width: calc(100px);
  color: rgb(171, 171, 171);
}
.add-on-add-button {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  padding: 0;
  height: 50px !important;
  width: 50px !important;
  color: var(--green);
}
.add-on-add-button:hover {
  color: #019f4a;
}
.add-on-title {
  font-weight: 900;
  font-size: 18px;
  color: black;
  padding-bottom: 5px;
  opacity: 1;
}
.add-on-summary {
  font-size: 12px;
  opacity: 0.7;
}
.add-on-payment {
  color: black;
  font-weight: 900;
  opacity: 1;
  font-size: 16px;
}

.add-on-input {
  width: 30px;
  height: 30px;
  text-align: center;
  border-radius: 50%;
  border: none;
  background-color: rgb(248, 248, 248);
}
/* firefox */
.add-on-input[type="number"] {
  appearance: textfield;
}

.add-on-input::-webkit-outer-spin-button,
.add-on-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.add-on-select {
  height: 30px;
  width: 100px;
}

.error-handle {
  position: absolute;

  color: var(--red);
}
@media only screen and (max-width: 1500px) {
  .book-container {
    width: 60vw;
  }
}

@media only screen and (max-width: 1000px) {
  .book-container {
    width: 80vw;
  }
  .form-options {
    width: 70vw;
  }
  .personal-form,
  .form {
    width: 70vw;
  }
  .add-on-form .sub-container {
    width: 70vw;
  }
  .sub-container:after {
    width: 65vw;
  }
  .add-on-details {
    width: calc(70vw - 240px);
  }
  .book-steps {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 15px;
    width: calc(70vw - 30px);
    height: 100px;
    /* border: 1px solid black; */
  }
  .bar {
    width: calc((70vw / 2) - 30px);
  }
  .controls-container {
    width: 68%;
    display: flex;
    justify-content: space-between;
  }
  .controls {
    width: 100vw;
    background: #eff2f7;
    bottom: 0;
    bottom: env(safe-area-inset-bottom);
  }
}
@media only screen and (max-width: 900px) {
  .book-container {
    width: 90vw;
  }
  .form-options {
    width: 80vw;
  }
  .personal-form,
  .form {
    width: 80vw;
  }
  .add-on-form .sub-container {
    width: 80vw;
  }
  .sub-container:after {
    width: 70vw;
  }
  .add-on-details {
    width: calc(80vw - 240px);
  }
  .book-steps {
    width: calc(80vw - 30px);
  }
  .bar {
    width: calc((80vw / 2) - 30px);
  }
}

@media only screen and (max-width: 750px) {
  .book-container {
    width: 100vw;
  }
  .form-options {
    width: 90vw;
  }
  .personal-form,
  .form {
    width: 90vw;
    padding-bottom: 125px;
  }
  .add-on-form .sub-container {
    width: 90vw;
  }
  .sub-container:after {
    width: 80vw;
  }
  .add-on-details {
    width: calc(90vw - 160px);
    height: 120px;
  }
  .book-steps {
    width: calc(90vw - 30px);
  }
  .bar {
    width: calc((90vw / 2) - 30px);
  }

  .add-on-image {
    height: 120px;
    width: 150px;
    display: none;
  }
  .add-on-add {
    width: 60px;
    display: none;
  }
  .add-on-add-button {
    height: 35px !important;
    width: 35px !important;
  }
  .add-on-title {
    font-size: 13px;
  }
  .add-on-payment {
    font-size: 13px;
  }
  .add-on-small {
    display: flex;
    position: relative;
    height: 120px;
    width: 150px;
    margin: 0 10px 0 0px;
    overflow: hidden;
  }
  .add-on-small img {
    height: 120px;
    width: 150px;
    object-fit: fill;
  }
  .image-filter {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(104, 118, 104, 0.661);
    transition-delay: 0.5s;
    transition: all 0.5s ease-in;
  }
  .add-on-checked {
    position: absolute;
    width: 100%;
    height: 100%;
    color: white;
    transition: all 0.5s ease-in;
  }
  .add-on-checked h2 {
    background: var(--green);
    font-size: 15px;
    height: 100%;
    padding: 0 4px;
    border-left: 1px dotted #f8f8f8;
    text-align: center;
    transform: rotate(180deg);
    writing-mode: vertical-rl;
  }
  .add-on-small-add {
    position: absolute;
    left: 50%;
    top: 50%;
    /* transform: translate(-50%, -50%); */
  }
  .add-on-add-button {
    color: white;
    height: 50px !important;
    width: 50px !important;
  }
  .add-on-add-button:hover {
    color: #e4e4e4;
  }
  .add-on-add-exit {
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
    padding: 0;
    color: white;
  }
  .rdrMonths,
  .rdrMonthsHorizontal {
    font-size: 10px;
  }
  .error-handle {
    top: -25px;
  }
  .controls-container {
    width: 90%;
    display: flex;
    justify-content: space-between;
  }
  .controls {
    width: 100vw;
    position: fixed;
    bottom: 0;
    bottom: env(safe-area-inset-bottom);
  }
  .controls-button {
    padding: 2px 10px;
    font-size: 15px;
  }
}
