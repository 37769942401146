.landing {
  width: 100%;
  height: 100vh;
  position: relative;
  font-family: "Sansita Swashed", Cambria, Cochin, Georgia, Times,
    "Times New Roman", serif;
}
.darken {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background: linear-gradient(
    to bottom,
    rgba(1, 1, 1, 0.74) 0%,
    rgba(1, 1, 1, 0.244) 70%,
    rgba(1, 1, 1, 0) 100%
  );
  z-index: 1;
}
.landing-slide-container {
  overflow: hidden;
  height: calc(95vh);
  width: 100%;
  z-index: 1;
  position: relative;
}
.slideshowSlider {
  height: 100%;
  width: 100%;
  white-space: nowrap;
}
.slide {
  height: 100vh;
  width: 100vw;
  background-repeat: no-repeat;
  object-fit: cover;
  z-index: 1;

  /* transition: all 8s linear; */
}
.grow {
  animation: zoom 8.025s ease-in infinite;
}

@keyframes zoom {
  0% {
    transform: scale(1);
  }
  5% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.2);
  }
}

.landing-content {
  position: absolute;
  transform: translate(-50%, 50%);
  left: 50%;
  bottom: 50%;
  z-index: 1;
}
.content-info {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  color: white;
  transition: all 2s linear;
}

.info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  font-family: "Roboto", Cambria, Cochin, Georgia, Times, "Times New Roman",
    serif;
  text-shadow: 0 0 2px rgba(30, 30, 30, 0.605);
  position: relative;
  font-size: calc(var(--fs) + 2px);
}

.info-illustration {
  position: absolute;
  display: none;
  bottom: 0%;
  left: 0%;
  height: 15vh;
  overflow: hidden;
  z-index: -1;
  opacity: 0.7;
}

.description {
  z-index: 2;
  text-align: center;
  font-weight: 900;
}
.info-button {
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  color: white !important;
  border: none;
  background-color: none;
  text-decoration: none;
  font-size: calc(var(--fs) + 6px);
  border-radius: 8px;
  position: relative;
  overflow: hidden;
  text-shadow: 0 0 2px rgba(213, 213, 213, 0.605);
  padding: 5px 10px;
  cursor: pointer;
  margin: 20px -10px 0 0;
  transition: transform 0.3s;
  /* background-color: #f8f8f8; */
  border: 1px solid #f8f8f8;
}

.pagination {
  position: absolute;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  border: 1px solid white;
  right: 20px;
  top: 50%;
  cursor: pointer;
  filter: grayscale(100%);
  z-index: 2;
  transition: filter 0.5s ease-in;
  margin: calc(30px * var(--marginIndex)) 0;
}

.pagination:hover {
  filter: grayscale(0);
}
.pagination.active {
  filter: grayscale(0);
}
.collections-landing {
  display: flex;
  align-items: center;
  position: absolute;
  left: -20px;
  bottom: 50%;
  transform: translate(0, 50%) rotate(270deg);
  color: white;
  font-size: 13px;
  background: rgba(0, 0, 0, 0.708);
  text-transform: uppercase;
  padding: 5px 10px;
  border-radius: 20px;
  cursor: pointer;
  z-index: 2;
}
.collections-icon {
  margin: 0 5px;
}

.mySwiper {
  position: fixed !important;
  top: 0px;
  background-color: rgba(0, 0, 0, 0.9);
  left: 0;
  height: calc(100vh);
  width: 100%;
  z-index: 200 !important;
}

.mySwiper .swiper-slide {
  height: 100%;
  width: 100%;
  display: flex !important;
  justify-content: center;
  align-items: center;
  /* position: relative !important; */
}

.mySwiper .swiper-slide img {
  width: 80%;
  height: auto;
  max-height: 95vh;
  object-fit: fill;
  border: 5px solid white;
  z-index: 300;
}
iframe {
  border: 5px solid white;
  z-index: 300;
  height: 80vh;
  width: 80%;
}
.mySwiper .swiper-button-next,
.mySwiper .swiper-button-prev {
  color: white !important;
  margin: 0 5%;
}
.close-icon {
  position: absolute !important;
  right: 5%;
  top: 5%;
  z-index: 100 !important;
  color: white !important;
  font-size: 40px !important;
  cursor: pointer;
  transition: all 300ms linear !important;
}
.close-icon:hover {
  transform: scale(1.1) !important;
}
.react-player {
  z-index: 400;
  border: 2px solid white;
  height: 60%;
  width: 80%;
}
@media only screen and (max-width: 1200px) {
  .info {
    font-size: calc(var(--fs) + 1px);
  }
}
@media only screen and (max-width: 1000px) {
  .info {
    font-size: calc(var(--fs));
  }
  .landing-content {
    width: 70%;
  }
  .mySwiper .swiper-button-next,
  .mySwiper .swiper-button-prev {
    color: white !important;
    margin: 0 0%;
  }
}
@media only screen and (max-width: 900px) {
  .mySwiper .swiper-slide img,
  video {
    width: 95%;
    max-height: 60%;
  }
  iframe {
    width: 95%;
    max-height: 60%;
  }
  .mySwiper {
    flex-direction: column !important;
  }
  .mySwiper .swiper-wrapper,
  .swiper-slide {
  }
  .mySwiper .swiper-button-next:after,
  .mySwiper .swiper-button-prev:after {
    color: white !important;
    margin: 0 0%;
    width: 0;
    height: 0;
    position: fixed !important;
    transform: rotate(90deg);
    left: calc(50% + 20px);
  }
  .mySwiper .swiper-button-next:hover:after,
  .mySwiper .swiper-button-prev:hover:after {
    transform: rotate(90deg) scale(1.05);
  }
  .mySwiper .swiper-button-next:after {
    bottom: 40px !important;
  }
  .mySwiper .swiper-button-prev:after {
    top: 40px !important;
  }
}
@media only screen and (max-width: 800px) {
  .landing-slide-container {
    height: 75vh;
  }

  .info {
    font-size: calc(var(--fs) - 1px);
  }

  .info-button {
    font-size: calc(var(--fs) + 4px);
  }
}

@media only screen and (max-width: 600px) {
  .info {
    font-size: calc(var(--fs) - 2px);
    background: radial-gradient(rgba(0, 0, 0, 0.868), rgba(0, 0, 0, 0.022));
    box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.289);
    padding: 20px;
    border-radius: 10px;
  }
  .landing-content {
    bottom: 50%;

    transform: translate(-50%, 50%);
    width: 70%;
  }
  .pagination {
    height: 15px;
    width: 15px;
    top: unset;
    margin: 0 calc(30px * var(--marginIndex));
    right: 50%;
    transform: translate(50%, -50%);
    bottom: 10px;
    z-index: 2;
  }
  .info-button {
    font-size: calc(var(--fs) + 2px);
  }
}
@media only screen and (max-width: 450px) {
  .landing-slide-container {
    height: 65vh;
  }
  .landing-content {
    bottom: 40%;
    width: 80%;
  }
  .info {
    font-size: calc(var(--fs) - 3px);
  }
  .info-button {
    font-size: calc(var(--fs));
  }
}
