.attractions-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10vh;
  width: 90%;
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.245);
  border-radius: 15px;
  font-family: "Roboto", Cambria, Cochin, Georgia, Times, "Times New Roman",
    serif;
}
.attractions-header {
  text-align: center;
  width: 50%;
  padding-top: 10px;
}
.attractions-header h5 {
  font-family: "Satisfy", "Roboto", Cambria, Cochin, Georgia, Times,
    "Times New Roman", serif;
  font-size: 40px;
  color: var(--green);
}
.attractions-header h2 {
  font-size: 50px;
  font-family: "Roboto", Cambria, Cochin, Georgia, Times, "Times New Roman",
    serif;
  padding-bottom: 10px;
}
.attractions-header p {
  font-weight: 400;
  font-size: calc(var(--fs));
  line-height: calc(var(--fs) + 5px);
}

.attraction-cards-container {
  padding: 40px 0;
  width: 90%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-items: center;
  align-content: center;
}
.attraction-cards {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  height: 700px;
  width: 500px;
  margin: 20px;
  border-radius: 15px;
  box-shadow: 2px 2px 12px 0px rgb(0 0 0 / 15%);
  transition: transform 0.5s ease-in;
}

.attraction-cards:hover {
  transform: translate(0, -10px);
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.246);
}
.attraction-images {
  height: 50%;
  width: 100%;
  object-fit: cover;
  overflow: hidden;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  cursor: pointer;
}
.card-overlay {
  position: absolute;
  bottom: 0;
  height: 50%;
  width: 100%;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  background-color: rgba(104, 118, 104, 0.661);
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  cursor: pointer;
  text-decoration: none;
}
.attraction-details {
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.attraction-title {
  text-align: center;
  font-size: 20px;
  height: 30%;
  display: flex;
  flex-direction: column;
  padding: 5px;
  align-items: center;
  justify-content: center;
  font-size: calc(var(--fs) + 3px);
  color: var(--green);
  position: relative;
}
.attraction-title:after {
  content: "";
  height: 1px;
  width: 80%;
  background-color: black;
  position: absolute;
  bottom: 5px;
}
.attraction-description {
  height: 70%;
  width: 90%;
  /* font-size: calc(var(--fs)); */
  display: flex;
  align-items: center;
  text-align: center;
}

.attraction-link,
.hovered {
  padding: 5px 10px;
  border-radius: 5px;
  font-size: calc(var(--fs));
}
.hovered {
  border: 1px solid white;
}

@media only screen and (max-width: 2700px) {
  .attraction-cards {
    height: 600px;
    width: 420px;
  }
  .attraction-description {
    font-size: calc(var(--fs) - 1px);
  }
}
@media only screen and (max-width: 2300px) {
  .attraction-cards {
    height: 540px;
    width: 350px;
    margin: 10px;
  }
  .attraction-description {
    font-size: calc(var(--fs) - 4px);
    line-height: calc(var(--fs));
  }
  .attraction-title {
    font-size: calc(var(--fs) - 1px);
  }
}
@media only screen and (max-width: 2200px) {
  .attraction-cards {
    height: 540px;
    width: 350px;
    margin: 10px;
  }
  .attraction-description {
    font-size: calc(var(--fs));
    line-height: calc(var(--fs) + 4px);
  }
  .attraction-title {
    font-size: calc(var(--fs) + 1px);
  }
}
@media only screen and (max-width: 1850px) {
  .attraction-cards {
    height: 470px;
    width: 300px;
    margin: 10px;
  }
  .attraction-description {
    font-size: calc(var(--fs) - 1px);
    line-height: calc(var(--fs) + 2px);
  }
  .attraction-title {
    font-size: calc(var(--fs));
  }
}
@media only screen and (max-width: 1600px) {
  .attraction-cards {
    height: 430px;
    width: 260px;
    margin: 10px;
  }
  .attraction-description {
    font-size: calc(var(--fs) - 1px);
    line-height: calc(var(--fs) + 2px);
  }
  .attraction-title {
    font-size: calc(var(--fs));
  }
}
@media only screen and (max-width: 1400px) {
  .attractions-container {
    width: 95%;
  }
  .attraction-cards {
    height: 400px;
    width: 230px;
    margin: 10px;
  }
  .attraction-description {
    font-size: calc(var(--fs) - 2px);
    line-height: calc(var(--fs) + 1px);
  }
  .attraction-title {
    font-size: calc(var(--fs) - 1px);
  }
}
@media only screen and (max-width: 1200px) {
  .attraction-cards {
    height: 360px;
    width: 200px;
    margin: 10px;
  }
  .attraction-description {
    font-size: calc(var(--fs) - 3px);
    line-height: calc(var(--fs));
  }
  .attraction-title {
    font-size: calc(var(--fs) - 2px);
  }
}
@media only screen and (max-width: 1050px) {
  .attractions-container {
    width: 100%;
    margin-top: 0;
    border-radius: 0;
    box-shadow: inset 0 0 10px 1px rgba(0, 0, 0, 0.245);
  }
  .attractions-header {
    width: 70%;
  }
  .attraction-cards-container {
    grid-template-columns: repeat(1, 1fr);
  }
  .attraction-cards {
    width: 100%;
    height: 270px;
    margin: 10px 0;
    flex-direction: row;
  }
  .attraction-cards:nth-child(even) {
    flex-direction: row-reverse;
  }
  .attraction-details {
    height: 100%;
    width: 50%;
  }
  .attraction-title {
    font-size: calc(var(--fs) + 2px);
  }
  .attraction-description {
    font-size: calc(var(--fs));
    line-height: calc(var(--fs) + 5px);
    width: 80%;
  }

  .attraction-images {
    width: 50%;
    height: 100%;
    border-bottom-left-radius: 0px;
    border-top-right-radius: 10px;
  }
  .attraction-cards:nth-child(even) .attraction-images {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 0px;
    border-top-left-radius: 10px;
    border-top-right-radius: 0px;
  }
  .card-overlay {
    width: 50%;
    height: 100%;
    right: 0;
    border-bottom-left-radius: 0px;
    border-top-right-radius: 10px;
  }
  .attraction-cards:nth-child(even) .card-overlay {
    width: 50%;
    height: 100%;
    left: 0;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 0px;
    border-top-left-radius: 10px;
    border-top-right-radius: 0px;
  }
}

@media only screen and (max-width: 800px) {
  .attraction-cards {
    width: 100%;
    height: 240px;
    flex-direction: row;
  }
  .attractions-header {
    width: 90%;
  }
  .attractions-header h2 {
    font-size: calc(var(--fs) + 30px);
  }
  .attractions-header h5 {
    font-size: calc(var(--fs) + 20px);
  }
  .attraction-description {
    font-size: calc(var(--fs) - 1px);
    line-height: calc(var(--fs) + 4px);
    width: 90%;
  }
  .attractions-header p {
    font-weight: 400;
    font-size: calc(var(--fs) - 1px);
    line-height: calc(var(--fs) + 4px);
  }
}

@media only screen and (max-width: 600px) {
  .attraction-cards {
    width: 100%;
    height: 200px;
    flex-direction: row;
  }
  .attraction-description {
    font-size: calc(var(--fs) - 2px);
    line-height: calc(var(--fs) + 3px);
    width: 90%;
  }
  .attractions-header h2 {
    font-size: calc(var(--fs) + 25px);
  }
  .attractions-header h5 {
    font-size: calc(var(--fs) + 18px);
  }
}
@media only screen and (max-width: 500px) {
  .attraction-description {
    font-size: calc(var(--fs) - 3px);
    line-height: calc(var(--fs) + 2px);
    width: 90%;
  }
  .attractions-header p {
    font-weight: 400;
    font-size: calc(var(--fs) - 2px);
    line-height: calc(var(--fs) + 3px);
  }
}
@media only screen and (max-width: 450px) {
  .attraction-description {
    font-size: calc(var(--fs) - 4px);
    line-height: calc(var(--fs) + 1px);
    width: 90%;
  }
  .attraction-title {
    font-size: calc(var(--fs) - 1px);
  }
}
@media only screen and (max-width: 350px) {
  .attraction-description {
    font-size: calc(var(--fs) - 5px);
    line-height: calc(var(--fs));
    width: 90%;
  }
  .attraction-title {
    font-size: calc(var(--fs) - 3px);
  }
}
